import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {FcmBody} from "../models/fcm-body";
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class FcmService {

    sendUrl = environment.fcmSendUrl;
    serverKey = environment.fcmServerKey;

    constructor(private httpClient: HttpClient) { }

    /**
     * 푸시 전송
     */
    public sendPush(fcmBody: FcmBody) {
        return this.httpClient.post(this.sendUrl, fcmBody.getData(), {
            headers: new HttpHeaders({
                'Content-Type': 'application/json; UTF-8'
                , Authorization: 'key=' + this.serverKey
            })
        });
    }

}
