import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ValidationFormComponent} from '../../../../common/validation-form.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CommonService} from '../../../../../service/common.service';
import {ActivatedRoute} from '@angular/router';
import {RestService} from '../../../../../service/rest.service';
import {AuthService} from '../../../../../service/auth.service';
import {GridDataResult, PageChangeEvent, RemoveEvent, SelectableSettings} from '@progress/kendo-angular-grid';


@Component({
    selector: 'app-board-post-view',
    templateUrl: './board-post-view.component.html',
    styleUrls: ['./board-post-view.component.css']
})
export class BoardPostViewComponent extends ValidationFormComponent implements OnInit, AfterViewInit {

    dataForm: FormGroup;
    pageData = {
        pstIdx: 0
    };
    viewData = {
        usrIdx: 0,
        name: '',
        subject: '',
        content: '',
        cmtCnt: 0,
        likeCnt: 0,
        readCnt: 0,
        bdName1: '',
        bdName2: '',
        imgFIdx: '',
        imgFSeq: '',
        imgFSvrName: '',
        imgFOrgName: '',
        etcFIdx: '',
        etcFSeq: '',
        etcFSvrName: '',
        etcFOrgName: '',
        regDt: '',
        linkUrl: ''
    };

    // 페이징 관련
    skip = 0;
    pageSize = 10;
    page = 1;
    selectableSettings: SelectableSettings = {
        checkboxOnly: true,
        mode: 'single'
    };
    rowsSelected: number[] = [];
    // 리스트 표시 데이터
    gridData: GridDataResult;
    selectedCallback = (args) => args.dataItem;

    constructor(private formBuilder: FormBuilder, private commonService: CommonService,
                private route: ActivatedRoute, private restService: RestService,
                private authService: AuthService) {
        super();
    }

    ngOnInit() {
        this.route.paramMap.subscribe (params => {
            this.pageData.pstIdx = Number(params.get('pstIdx'));
        });

        this.dataForm = this.formBuilder.group({
            P_STAT_CD: [null, Validators.required],
            P_FIXED_YN: [null, Validators.required]
        });

        this.f.P_STAT_CD.setValue('000'); // 셀렉트박스 디폴트
        this.f.P_FIXED_YN.setValue('000'); // 셀렉트박스 디폴트
    }

    async ngAfterViewInit() {
        await this.getData(); // 상세정보
        await this.getComment(); // 상세정보
    }

    get f() {
        return this.dataForm.controls;
    }

    /**
     * 데이터 조회 정의
     */
    getData() {
        this.restService.requestAPIAuth({
            P_NAME: 'SP_ADM_POST_R2',
            P_ARR: JSON.stringify({
                P_PST_IDX: this.pageData.pstIdx
            })
        }, 'prv', this.authService).subscribe((res) => {
            this.viewData.usrIdx = res.data.usrIdx;
            this.viewData.name = res.data.name;
            this.viewData.subject = res.data.subject;
            this.viewData.content = res.data.content;
            this.viewData.cmtCnt = res.data.cmtCnt;
            this.viewData.likeCnt = res.data.likeCnt;
            this.viewData.readCnt = res.data.readCnt;
            this.viewData.bdName1 = res.data.bdName1;
            this.viewData.bdName2 = res.data.bdName2;
            this.viewData.imgFIdx = res.data.imgFIdx;
            this.viewData.imgFSeq = res.data.imgFSeq;
            this.viewData.imgFSvrName = res.data.imgFSvrName;
            this.viewData.imgFOrgName = res.data.imgFOrgName;
            this.viewData.etcFIdx = res.data.etcFIdx;
            this.viewData.etcFSeq = res.data.etcFSeq;
            this.viewData.etcFSvrName = res.data.etcFSvrName;
            this.viewData.etcFOrgName = res.data.etcFOrgName;
            this.viewData.regDt = res.data.regDt;
            this.viewData.linkUrl = 'https://app.citymarket.co.kr/#/auth/boardView'
                + ';bdIdx=' + res.data.bdIdx
                + ';pstIdx=' + res.data.pstIdx
            ;
            this.f.P_STAT_CD.setValue(res.data.statCd);
            this.f.P_FIXED_YN.setValue(res.data.fixedYn);
        });
    }

    /**
     * 댓글 리스트
     */
    getComment() {
        this.restService.requestAPIGrid({
            P_NAME: 'SP_ADM_COMMENT_R',
            P_ARR: JSON.stringify({
                P_PST_IDX: this.pageData.pstIdx,
                P_PAGE: this.page,
                P_PAGESIZE: this.pageSize
            })
        }, 'prv', this.authService).subscribe((res) => {
            this.gridData = res;
        });
    }

    /**
     * 저장 및 수정
     */
    saveData() {
        this.restService.requestAPIAuth({
            P_NAME: 'SP_ADM_POST_U',
            P_ARR: JSON.stringify({
                P_PST_IDX: this.pageData.pstIdx
                , P_STAT_CD: this.f.P_STAT_CD.value
                , P_FIXED_YN: this.f.P_FIXED_YN.value
            })
        }, 'prv', this.authService).subscribe(res => {
            if (!res.result) {
                alert('데이터 처리에 실패하였습니다. ');
                return;
            }

            alert('수정 되었습니다.');
            this.commonService.movePage(['/auth/board/boardPostList'], true);
        });
    }

    // 댓글 페이징
    pageChange(event: PageChangeEvent): void {
        this.skip = event.skip;
        this.page = (event.skip / this.pageSize) + 1;
        // 데이터 조회
        this.getComment();
    }

    // 댓글 삭제
    removeComment(event: RemoveEvent) {
        if (!confirm('정말로 삭제하시겠습니까?')) {
            return;
        }
        this.restService.requestAPIAuth({
            P_NAME: 'SP_ADM_COMMENT_D',
            P_ARR: JSON.stringify({
                P_CMT_IDX: event.dataItem.cmtIdx
            })
        }, 'prv', this.authService).subscribe(res => {
            if (!res.result) {
                alert('데이터 삭제에 실패하였습니다.');
                return;
            }

            alert('삭제 되었습니다.');
            this.getComment();
        });
    }
}
