export class FcmBody {
    os: string;
    to: string;
    registration_ids: any = [];
    title: string;
    body: string;
    badge: number = 0;
    content_available: boolean = true;
    priority: string = 'high';

    public getData() {
        let data = {
            notification: {
                title: this.title
                , body: this.body
                , badge: this.badge
            }
        };
        if (this.registration_ids.length > 0) {
            data['registration_ids'] = this.registration_ids;
        } else {
            data['to'] = this.to;
        }
        if (this.os === 'I') {
            data['content_available'] = this.content_available;
            data['priority'] = this.priority;
        }
        return data;
    }
}
