import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {BoardGroupListComponent} from './content/boardGroup/board-group-list/board-group-list.component';
import {BoardGroupViewComponent} from './content/boardGroup/board-group-view/board-group-view.component';
import {BoardListComponent} from './content/board/board-list/board-list.component';
import {BoardViewComponent} from './content/board/board-view/board-view.component';
import {BoardPostListComponent} from './content/boardPost/board-post-list/board-post-list.component';
import {BoardPostViewComponent} from './content/boardPost/board-post-view/board-post-view.component';
import {MessageListComponent} from './content/message/message-list/message-list.component';
import {LevelListComponent} from './content/level/level-list/level-list.component';
import {LevelViewComponent} from './content/level/level-view/level-view.component';
import {AdminListComponent} from './content/user/admin-list/admin-list.component';
import {AdminViewComponent} from './content/user/admin-view/admin-view.component';
import {UserListComponent} from './content/user/user-list/user-list.component';
import {UserViewComponent} from './content/user/user-view/user-view.component';
import {ReportReasonListComponent} from './content/reportReason/report-reason-list/report-reason-list.component';
import {ReportReasonViewComponent} from './content/reportReason/report-reason-view/report-reason-view.component';
import {ReportListComponent} from './content/report/report-list/report-list.component';
import {ReportViewComponent} from './content/report/report-view/report-view.component';
import {VisualListComponent} from "./content/setting/visual-list/visual-list.component";
import {VisualViewComponent} from "./content/setting/visual-view/visual-view.component";
import {TermsComponent} from './content/setting/terms/terms.component';
import {SendPushComponent} from "./content/push/send-push/send-push.component";
import {PointListComponent} from "./content/point/point-list/point-list.component";
import {PointAddComponent} from "./content/point/point-add/point-add.component";


const routes: Routes = [
    {
        path: 'board', children: [
            { path: 'boardGroupList', component: BoardGroupListComponent },
            { path: 'boardGroupView', component: BoardGroupViewComponent },
            { path: 'boardList', component: BoardListComponent },
            { path: 'boardView', component: BoardViewComponent },
            { path: 'boardPostList', component: BoardPostListComponent },
            { path: 'boardPostView', component: BoardPostViewComponent }
        ]
    }
    , {
        path: 'user', children: [
            { path: 'adminList', component: AdminListComponent },
            { path: 'adminView', component: AdminViewComponent },
            { path: 'userList', component: UserListComponent },
            { path: 'userView', component: UserViewComponent }
        ]
    }
    , {
        path: 'level', children: [
            { path: 'levelList', component: LevelListComponent },
            { path: 'levelView', component: LevelViewComponent }
        ]
    }
    , {
        path: 'report', children: [
            { path: 'reportReasonList', component: ReportReasonListComponent },
            { path: 'reportReasonView', component: ReportReasonViewComponent },
            { path: 'reportList', component: ReportListComponent },
            { path: 'reportView', component: ReportViewComponent }
        ]
    }
    , {
        path: 'message', children: [
            { path: 'messageList', component: MessageListComponent }
        ]
    }
    , {
        path: 'setting', children: [
            { path: 'visualList', component: VisualListComponent },
            { path: 'visualView', component: VisualViewComponent },
            { path: 'terms', component: TermsComponent }
        ]
    }
    , {
        path: 'push', children: [
            { path: 'sendPush', component: SendPushComponent }
        ]
    }
    , {
        path: 'point', children: [
            { path: 'pointList', component: PointListComponent },
            { path: 'pointAdd', component: PointAddComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MainRoutingModule { }
