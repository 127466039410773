import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ValidationFormComponent} from '../../../../common/validation-form.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CommonService} from '../../../../../service/common.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RestService} from '../../../../../service/rest.service';
import {AuthService} from '../../../../../service/auth.service';
import {RestData} from '../../../../../models/rest-data';

@Component({
  selector: 'app-level-view',
  templateUrl: './level-view.component.html',
  styleUrls: ['./level-view.component.css']
})
export class LevelViewComponent extends ValidationFormComponent implements OnInit, AfterViewInit {

  dataForm: FormGroup;

  pageData = {
    levelIdx: 0,
    levelOrd: 0
  };

  constructor(private formBuilder: FormBuilder, private commonService: CommonService,
              private route: ActivatedRoute, private restService: RestService,
              private authService: AuthService, private router: Router) {
    super();
  }

  ngOnInit() {
    this.route.paramMap.subscribe (params => {
      this.pageData.levelIdx = Number(params.get('levelIdx'));
    });
    this.dataForm = this.formBuilder.group({
      P_LEVEL_ORD: [null]
      , P_LEVEL_NAME: [null, Validators.required]
      , P_MIN_POINT: ['', [Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]]
      , P_MAX_POINT: ['', [Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]]
    });
  }

  ngAfterViewInit() {
    this.getData();
  }

  get f() {
    return this.dataForm.controls;
  }

  /**
   * 데이터 조회 정의
   */
  getData() {
    if (this.pageData.levelIdx === 0) {
      return;
    }

    this.restService.requestAPIAuth({
      P_NAME: 'SP_ADM_LEVEL_R2',
      P_ARR: JSON.stringify({
        P_LEVEL_IDX: this.pageData.levelIdx
      })
    }, 'prv', this.authService).subscribe(res => {
      this.pageData.levelOrd = res.data.levelOrd;
      this.f.P_LEVEL_NAME.setValue(res.data.levelName);
      this.f.P_MIN_POINT.setValue(res.data.minPoint);
      this.f.P_MAX_POINT.setValue(res.data.maxPoint);
    });
  }

  /**
   * 저장 및 수정
   */
  saveData() {
    if (!this.dataForm.valid) {
      this.validateAllFormFields(this.dataForm);
      return;
    }

    let msg = ((this.pageData.levelIdx > 0) ? '수정 되었습니다.' : '등록 되었습니다.');
    const restData = new RestData();
    restData.P_NAME = 'SP_ADM_LEVEL_CU';
    restData.P_ARR = JSON.stringify({
      P_MODE: ((this.pageData.levelIdx > 0) ? 'U' : 'C'),
      P_LEVEL_IDX: this.pageData.levelIdx,
      P_LEVEL_NAME: this.f.P_LEVEL_NAME.value,
      P_MIN_POINT: this.f.P_MIN_POINT.value,
      P_MAX_POINT: this.f.P_MAX_POINT.value
    });
    this.restService.requestAPIAuth(restData, 'prv', this.authService).subscribe(res => {
      if (!res.result) {
        alert('데이터 처리에 실패하였습니다. ');
        return;
      }
      alert(msg);
      this.commonService.movePage(['/auth/level/levelList'], true);
    });
  }

}
