import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ValidationFormComponent} from '../../../../common/validation-form.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CommonService} from '../../../../../service/common.service';
import {ActivatedRoute} from '@angular/router';
import {RestService} from '../../../../../service/rest.service';
import {AuthService} from '../../../../../service/auth.service';

@Component({
    selector: 'app-report-view',
    templateUrl: './report-view.component.html',
    styleUrls: ['./report-view.component.css']
})
export class ReportViewComponent extends ValidationFormComponent implements OnInit, AfterViewInit {

    dataForm: FormGroup;
    pageData = {
        repoIdx: 0
    };
    viewData = {
        divName: '',
        repoDtName: '',
        bdName: '',
        bdPostSubject: '',
        repoContents: '',
        repoUserName: '',
        repoDt: '',
        procUserName: '',
        procContents: '',
        procDt: '',
        content: ''
    };

    constructor(private formBuilder: FormBuilder, private commonService: CommonService,
                private route: ActivatedRoute, private restService: RestService,
                private authService: AuthService) {
        super();
    }

    ngOnInit() {
        this.route.paramMap.subscribe (params => {
            this.pageData.repoIdx = Number(params.get('repoIdx'));
        });

        this.dataForm = this.formBuilder.group({
            P_PROC_CONTENT: [null],
            P_STAT_CD: [null, Validators.required]
        });

        this.f.P_STAT_CD.setValue('000'); // 셀렉트박스 디폴트
    }

    async ngAfterViewInit() {
        await this.getData(); // 상세정보
    }

    get f() {
        return this.dataForm.controls;
    }

    /**
     * 데이터 조회 정의
     */
    getData() {
        this.restService.requestAPIAuth({
            P_NAME: 'SP_ADM_REPORT_R2',
            P_ARR: JSON.stringify({
                P_REPO_IDX: this.pageData.repoIdx
            })
        }, 'prv', this.authService).subscribe((res) => {
            this.viewData.divName = res.data.divName;
            this.viewData.repoDtName = res.data.repoDtName;
            this.viewData.bdName = res.data.bdName;
            this.viewData.bdPostSubject = res.data.bdPostSubject;
            this.viewData.content = (res.data.divCd === '000')? res.data.bdPostContent : res.data.cmtContent;
            this.viewData.repoContents = res.data.repoContents;
            this.viewData.repoUserName = res.data.repoUserName;
            this.viewData.repoDt = res.data.repoDt;
            this.viewData.procUserName = this.authService.principalValue.usrNm;
            this.viewData.procDt = res.data.procDt;
            this.viewData.procContents = res.data.procContents;
            this.f.P_PROC_CONTENT.setValue(res.data.procContents);
            this.f.P_STAT_CD.setValue(res.data.statCd);
        });
    }

    /**
     * 저장 및 수정
     */
    saveData() {
        this.restService.requestAPIAuth({
            P_NAME: 'SP_ADM_REPORT_U',
            P_ARR: JSON.stringify({
                P_REPO_IDX: this.pageData.repoIdx
                , P_PROC_IDX: this.authService.principalValue.usrIdx
                , P_PROC_CONTENT: this.f.P_PROC_CONTENT.value
                , P_STAT_CD: this.f.P_STAT_CD.value
            })
        }, 'prv', this.authService).subscribe(res => {
            if(!res.result) {
                alert('데이터 처리에 실패하였습니다. ');
                return;
            }

            alert('수정 되었습니다.');
            this.commonService.movePage(['/auth/report/reportList'], true);
        });
    }

}
