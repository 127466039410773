import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {RestData} from '../models/rest-data';
import {AuthService} from './auth.service';
import {map} from 'rxjs/operators';
import {Commons} from '../helpers/commons';
import {GridDataResult} from '@progress/kendo-angular-grid';

@Injectable({
    providedIn: 'root'
})
export class RestService {

    commons = new Commons();
    restURL: string = environment.restUrl;
    authURL: string = environment.authUrl;

    constructor(private httpClient: HttpClient) { }

    /**
     * SP 외에 별도 Rest 통신시 사용할 공용 함수
     * 서버측에서는 @RequestBody 로 바디 데이터를 받도록 설계해야함.
     * @param data 전달할 데이터. JSON.stringify() 로 감싸서 전달해야함
     */
    public requestRest(data: any) {
        return this.httpClient.post(`${this.authURL}`, data, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json; charset=utf-8'
                    , 'Accept': 'application/json; charset=utf-8'
                    // , 'Cache-Control': 'no-cache'
                })
                , responseType: 'json'
                , observe: 'body'
            }
        );
    }

    // /**
    //  * REST API 통신 공용함수
    //  * @param restData 보낼 데이터
    //  */
    // public requestAPI(restData: RestData, pattern: string) {
    //     const formData: FormData = new FormData();
    //     formData.append('P_NAME', restData.P_NAME);
    //     formData.append('P_ARR', restData.P_ARR);
    //     return this.httpClient.post(`${this.restURL + '/' + pattern}`, formData, {
    //             responseType: 'json',
    //             observe: 'body'
    //         }
    //     );
    // }

    /**
     * REST API 통신 공용함수
     * @param restData 보낼 데이터
     */
    public requestAPIAuth(restData: RestData, pattern: string, authService: AuthService, arr: boolean = false) {
        const formData: FormData = new FormData();
        formData.append('P_NAME', restData.P_NAME);
        formData.append('P_ARR', restData.P_ARR);
        return this.httpClient.post(`${this.restURL + '/' + pattern}`, formData, {
                responseType: 'json',
                observe: 'body',
                headers: {
                    Authorization: `Bearer ${authService.principalValue.token}`
                }
            }
        ).pipe(
            map(response => ({
                result: this.commons.getResponseResult(response),
                data: ((arr) ? this.commons.getResponseListData(response) : this.commons.getResponseData(response))
            }))
        );
    }

    /**
     * REST API 통신 공용함수
     * @param restData 보낼 데이터
     */
    public requestAPIGrid(restData: RestData, pattern: string, authService: AuthService) {
        const formData: FormData = new FormData();
        formData.append('P_NAME', restData.P_NAME);
        formData.append('P_ARR', restData.P_ARR);
        return this.httpClient.post(`${this.restURL + '/' + pattern}`, formData, {
                responseType: 'json',
                observe: 'body',
                headers: {
                    Authorization: `Bearer ${authService.principalValue.token}`
                }
            }
        ).pipe(
            map(response => ({
                result: this.commons.getResponseResult(response),
                data: this.commons.defaultGridSchemaDataHandler(response),
                total: this.commons.getResponseTotalCount(response)
            } as GridDataResult))
        );
    }

}
