/* tslint:disable:no-trailing-whitespace max-line-length radix */
import {Component} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';


@Component({
    template: ''
})
export class ValidationFormComponent {

    validTextType = false;
    validEmailType = false;
    validNumberType = false;
    validUrlType = false;
    pattern = 'https?://.+';
    validSourceType = false;
    validConfirmPassword = false;

    isFieldValid(form: FormGroup, field: string) {
        return !form.get(field).valid && form.get(field).touched;
    }

    displayFieldCss(form: FormGroup, field: string) {
        return {
            'has-error': this.isFieldValid(form, field),
            'has-feedback': this.isFieldValid(form, field),
            'has-success': form.get(field).valid && form.get(field).touched
        };
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    textValidationType(e) {
        if (e) {
            this.validTextType = true;
        } else {
            this.validTextType = false;
        }
    }

    emailValidationType(e) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(String(e).toLowerCase())) {
            this.validEmailType = true;
        } else {
            this.validEmailType = false;
        }
    }

    numberValidationType(e) {
        if (e) {
            this.validNumberType = true;
        } else {
            this.validNumberType = false;
        }
    }

    urlValidationType(e) {
        try {
            // tslint:disable-next-line:no-unused-expression
            new URL(e);
            this.validUrlType = true;
        } catch (_) {
            this.validUrlType = false;
        }
    }

    sourceValidationType(e) {
        if (e) {
            this.validSourceType = true;
        } else {
            this.validSourceType = false;
        }
    }

    confirmPasswordValidation(e, e2) {
        if (e === e2) {
            this.validConfirmPassword = true;
        } else {
            this.validConfirmPassword = false;
        }
    }

}
