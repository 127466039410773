import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ValidationFormComponent} from '../../../../common/validation-form.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CommonService} from '../../../../../service/common.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RestService} from '../../../../../service/rest.service';
import {AuthService} from '../../../../../service/auth.service';
import {RestData} from '../../../../../models/rest-data';

@Component({
  selector: 'app-report-reason-view',
  templateUrl: './report-reason-view.component.html',
  styleUrls: ['./report-reason-view.component.css']
})
export class ReportReasonViewComponent extends ValidationFormComponent implements OnInit, AfterViewInit {

  dataForm: FormGroup;

  pageData = {
    repoRsIdx: 0,
  };

  constructor(private formBuilder: FormBuilder, private commonService: CommonService,
              private route: ActivatedRoute, private restService: RestService,
              private authService: AuthService, private router: Router) {
    super();
  }

  ngOnInit() {
    this.route.paramMap.subscribe (params => {
      this.pageData.repoRsIdx = Number(params.get('repoRsIdx'));
    });
    this.dataForm = this.formBuilder.group({
      P_ORD: [null, Validators.required]
      , P_CONTENT: [null, Validators.required]
      , P_STAT_CD: [null, Validators.required]
    });

    this.f.P_STAT_CD.setValue('000'); // 셀렉트박스 디폴트
  }

  ngAfterViewInit() {
    this.getData();
  }

  get f() {
    return this.dataForm.controls;
  }

  /**
   * 데이터 조회 정의
   */
  getData() {
    if (this.pageData.repoRsIdx === 0) {
      return;
    }

    this.restService.requestAPIAuth({
      P_NAME: 'SP_ADM_REASON_R2',
      P_ARR: JSON.stringify({
        P_REPO_RS_IDX: this.pageData.repoRsIdx
      })
    }, 'prv', this.authService).subscribe(res => {
      this.f.P_ORD.setValue(res.data.ord);
      this.f.P_CONTENT.setValue(res.data.content);
      this.f.P_STAT_CD.setValue(res.data.statCd);
    });
  }

  /**
   * 저장 및 수정
   */
  saveData() {
    if (!this.dataForm.valid) {
      this.validateAllFormFields(this.dataForm);
      return;
    }

    let msg = ((this.pageData.repoRsIdx > 0) ? '수정 되었습니다.' : '등록 되었습니다.');
    const restData = new RestData();
    restData.P_NAME = 'SP_ADM_REASON_CU';
    restData.P_ARR = JSON.stringify({
      P_MODE: ((this.pageData.repoRsIdx > 0) ? 'U' : 'C'),
      P_REPO_RS_IDX: this.pageData.repoRsIdx,
      P_ORD: this.f.P_ORD.value,
      P_CONTENT: this.f.P_CONTENT.value,
      P_STAT_CD: this.f.P_STAT_CD.value
    });
    this.restService.requestAPIAuth(restData, 'prv', this.authService).subscribe(res => {
      if (!res.result) {
        alert('데이터 처리에 실패하였습니다. ');
        return;
      }
      alert(msg);
      this.commonService.movePage(['/auth/report/reportReasonList'], true);
    });
  }

}
