import { Component, OnInit } from '@angular/core';
import {BaseListComponent} from '../../../../common/base-list.component';
import {FormBuilder, FormGroup} from '@angular/forms';
import {RestService} from '../../../../../service/rest.service';
import {AuthService} from '../../../../../service/auth.service';
import {CommonService} from '../../../../../service/common.service';
import {EditEvent} from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-level-list',
  templateUrl: './level-list.component.html',
  styleUrls: ['./level-list.component.css']
})
export class LevelListComponent extends BaseListComponent implements OnInit {

  searchForm: FormGroup;

  constructor(private restService: RestService, private authService: AuthService, private commonService: CommonService,
              private formBuilder: FormBuilder) {
    super();
  }

  ngOnInit() {
    this.searchForm = this.formBuilder.group({
      P_SEARCH_KEY: ['']    // 검색인자
      , P_SEARCH_VALUE: ['']  // 검색어
    });

    // 검색인자 디폴트
    this.f.P_SEARCH_KEY.setValue((this.listItems.length > 0)? this.listItems[0].value : '');

    this.getData();
  }

  get f() {
    return this.searchForm.controls;
  }

  /**
   * 검색 구분자 정의
   */
  initSearchListItems() {
    this.listItems = [
      { text: '검색인자 선택.', value: '' }
      , { text: '레벨명', value: '1' }
    ];
  }

  /**
   * 검색
   */
  listSearch() {
    if(this.f.P_SEARCH_KEY.value != '' || this.f.P_SEARCH_VALUE.value != '') {
      if (this.f.P_SEARCH_KEY.value == '') {
        alert('검색인자를 선택해주세요.');
        return false;
      }
      if (this.f.P_SEARCH_VALUE.value == '') {
        alert('검색어를 입력해주세요.');
        return false;
      }
    }
    this.getData();
  }

  /**
   * 데이터 조회 정의
   */
  getData() {
    this.restService.requestAPIGrid({
      P_NAME: 'SP_ADM_LEVEL_R',
      P_ARR: JSON.stringify({
        P_SEARCH_KEY: this.f.P_SEARCH_KEY.value,
        P_SEARCH_VALUE: this.f.P_SEARCH_VALUE.value,
        P_PAGE: this.page,
        P_PAGESIZE: this.pageSize
      })
    }, 'prv', this.authService).subscribe(res => {
      this.gridData = res;
    });
  }

  editComment(event: EditEvent) {
    this.commonService.movePage(['/auth/level/levelView', { levelIdx: event.dataItem.levelIdx }], false);
  }
}
