import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {AuthService} from '../service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate  {

  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authService.principalValue;
    // console.log('currentUser::: ', currentUser);
    if (currentUser) {
      if (currentUser.token && this.authService.isValid(currentUser.token)) {
        return true;
      }
    }
    this.router.navigate(['/login']);
    return false;
  }
}
