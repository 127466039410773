import {AfterViewInit, Component, OnInit} from '@angular/core';
import {RestService} from "../../../../../service/rest.service";
import {AuthService} from "../../../../../service/auth.service";
import {CommonService} from "../../../../../service/common.service";
import {ActivatedRoute} from "@angular/router";
import {RestData} from "../../../../../models/rest-data";

declare var $: any;

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit, AfterViewInit {

  pageData = {
    termsList: []
  };

  constructor(private commonService: CommonService,
              private route: ActivatedRoute, private restService: RestService,
              private authService: AuthService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getData();
  }

  /**
   * 데이터 조회 정의
   */
  getData() {
    this.restService.requestAPIAuth({
      P_NAME: 'SP_ADM_TERMS_R',
      P_ARR: JSON.stringify({})
    }, 'prv', this.authService, true).subscribe(res => {
      this.pageData.termsList = [];
      if (res.data.length > 0) {
        this.pageData.termsList = res.data[0];
      }
    });
  }

  /**
   * 저장 및 수정
   */
  saveData(termsIdx: number) {
    if ($('#subject_' + termsIdx).val().trim() === '') {
      alert('제목을 입력해주세요.');
      $('#subject_' + termsIdx).focus();
      return;
    }
    if ($('#content_' + termsIdx).val().trim() === '') {
      alert('내용을 입력해주세요.');
      $('#content_' + termsIdx).focus();
      return;
    }

    const restData = new RestData();
    restData.P_NAME = 'SP_ADM_TERMS_U';
    restData.P_ARR = JSON.stringify({
      P_TERMS_IDX: termsIdx,
      P_SUBJECT: $('#subject_' + termsIdx).val(),
      P_CONTENT: $('#content_' + termsIdx).val()
    });
    this.restService.requestAPIAuth(restData,'prv', this.authService).subscribe(res => {
      if (!res.result) {
        alert('데이터 처리에 실패하였습니다. ');
        return;
      }
      alert('저장 되었습니다.');
      this.getData();
    });


  }

}
