import { Component, OnInit } from '@angular/core';
import {BaseListComponent} from '../../../../common/base-list.component';
import {FormBuilder, FormGroup} from '@angular/forms';
import {RestService} from '../../../../../service/rest.service';
import {AuthService} from '../../../../../service/auth.service';
import {CommonService} from '../../../../../service/common.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent extends BaseListComponent implements OnInit {

  searchForm: FormGroup;

  constructor(private restService: RestService, private authService: AuthService, private commonService: CommonService,
              private formBuilder: FormBuilder) {
    super();
  }

  ngOnInit() {

    this.selectableSettings.mode = 'multiple';

    this.searchForm = this.formBuilder.group({
      P_SEARCH_KEY: ['']    // 검색인자
      , P_SEARCH_VALUE: ['']  // 검색어
    });

    // 검색인자 디폴트
    this.f.P_SEARCH_KEY.setValue((this.listItems.length > 0)? this.listItems[0].value : '');

    this.getData();
  }

  get f() {
    return this.searchForm.controls;
  }

  /**
   * 검색 구분자 정의
   */
  initSearchListItems() {
    this.listItems = [
      { text: '검색인자 선택.', value: '' }
      , { text: '이메일', value: '1' }
      , { text: '이름', value: '2' }
      , { text: '레벨', value: '3' }
    ];
  }

  /**
   * 검색
   */
  listSearch() {
    if(this.f.P_SEARCH_KEY.value != '' || this.f.P_SEARCH_VALUE.value != '') {
      if (this.f.P_SEARCH_KEY.value == '') {
        alert('검색인자를 선택해주세요.');
        return false;
      }
      if (this.f.P_SEARCH_VALUE.value == '') {
        alert('검색어를 입력해주세요.');
        return false;
      }
    }
    this.getData();
  }

  /**
   * 데이터 조회 정의
   */
  getData() {
    this.restService.requestAPIGrid({
      P_NAME: 'SP_ADM_USER_R',
      P_ARR: JSON.stringify({
        P_SEARCH_KEY: this.f.P_SEARCH_KEY.value,
        P_SEARCH_VALUE: this.f.P_SEARCH_VALUE.value,
        P_PAGE: this.page,
        P_PAGESIZE: this.pageSize
      })
    }, 'prv', this.authService).subscribe(res => {
      this.gridData = res;
    });
  }

  /**
   * 항목 추가 페이지 이동
   */
  addItem() {
    this.commonService.movePage(['/auth/user/userView', { usrIdx: 0 }], false);
  }

  /**
   * 항목 삭제 처리
   */
  deleteItem() {
    if(this.rowsSelected.length === 0) {
      alert('삭제할 항목을 선택해주세요.');
      return;
    }
    if(!confirm('정말로 삭제하시겠습니까?')) {
      return;
    }

    let delData = '';
    const item = this.rowsSelected;
    for (let i = 0; i < item.length; i++) {
      if (i != 0) { delData += '|'; }
      delData += item[i]['usrIdx'];
    }

    this.restService.requestAPIAuth({
      P_NAME: 'SP_ADM_USER_D',
      P_ARR: JSON.stringify({
        P_DATA: delData
      })
    }, 'prv', this.authService).subscribe(res => {
      if(!res.result) {
        alert('데이터 삭제에 실패하였습니다.');
        return;
      }

      alert('삭제 하였습니다.');
      this.getData();
    });
  }

}
