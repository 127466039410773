/* tslint:disable:no-trailing-whitespace max-line-length no-redundant-jsdoc prefer-for-of */
import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import swal from 'sweetalert2';
import {AuthService} from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class CommonService {

  fileURL: string = environment.fileUrl;

  constructor(private router: Router, private location: Location,
              private httpClient: HttpClient, private authService: AuthService) {
  }

  /**
   * 페이지 이동
   * @param routerLink    이동 주소
   * @param replace       replace 여부
   */
  movePage(routerLink, replace) {
    if (replace) {
      this.router.navigate(routerLink, { replaceUrl: true });
    } else {
      this.router.navigate(routerLink);
    }
  }

  /**
   * 뒤로가기
   */
  onBack() {
    console.log('onBackPressed 뒤로가기');
    this.location.back();
  }

  /**
   * 파일 업로드
   * @param file    파일 리스트
   * @param type    생성 폴더명
   */
  fileUpload(file: FileList, type: string) {
    if (file) {
      const data = new FormData();
      for (let i = 0; i < file.length; i++) {
        data.append('files[]', file[i]);
      }
      data.append('type', type);
      return this.httpClient.post(this.fileURL + '/fileUpload', data).toPromise().then((res) => {
        // console.log('fileUpload res :: ', res);
        // @ts-ignore
        if (res.status === 'success') {
          // @ts-ignore
          return Number(res.data.fIdx);
        } else {
          // @ts-ignore
          console.log(res.data.message);
          return -1;
        }
      }, error => {
        console.log('error: ', error);
        return -1;
      });
    } else {
      return -1;
    }
  }

  /**
   * 파일 삭제
   * @param fIdx    삭제할 파일 인덱스
   */
  fileDelete(fIdx: number) {
    const data = new FormData();
    // @ts-ignore
    data.append('fIdx', fIdx);
    return this.httpClient.post(this.fileURL + '/fileDelete', data).toPromise().then((res) => {
      // console.log('fileDelete res :: ', res);
      // @ts-ignore
      if (res.status === 'success') {
        // @ts-ignore
        console.log('삭제 성공.');
      } else {
        // @ts-ignore
        console.log(res.data.message);
      }
    }, error => {
      console.log('error: ', error);
    });
  }

  // 로그아웃
  onLogout() {
    swal({
      title: '안내',
      text: '로그아웃 하시겠습니까?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: 'Yes',
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.authService.logout();
        this.router.navigate(['/login']);
      }
    });
  }

}
