import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {JwtHelperService} from '@auth0/angular-jwt';

import {map} from 'rxjs/operators';
import {User} from '../models/user';
import {RestService} from './rest.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  currentUserSubject: BehaviorSubject<User>;

  constructor(private restService: RestService, private jwtHelper: JwtHelperService) {
    // console.log('principals:: ', this.getPrincipals());
    this.currentUserSubject = new BehaviorSubject<User>(this.getPrincipals());
  }

  public get principalValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    const data = JSON.stringify({
        P_ID: username,
        P_PWD: password,
        P_SP_NAME: 'SP_ADM_LOGIN_R',
    });
    // console.log('login params::: ', data);

    return this.restService.requestRest(data).pipe(map(res => {
      // console.log('rest::::: ', res);
      const principal = this.extractPrincipals(res['token']);
      // console.log('principal::: ', principal);
      this.setPrincipals(principal);
      this.currentUserSubject.next(principal);
      return principal;
    }));

  }

  logout() {
    this.removePrincipals();
    this.currentUserSubject.next(null);
  }

  isValid(token: string): boolean {
    return token ? !this.isTokenExpired(token) : false;
  }

  isTokenExpired(token: string) {
    return this.jwtHelper.isTokenExpired(token);
  }

  extractPrincipals(token: string): User {
    const extracted = this.jwtHelper.decodeToken(token);
    return new User(extracted.usrIdx, extracted.usrId, extracted.usrNm, token);
  }

  setPrincipals(user: User): void {
    sessionStorage.setItem('principal', JSON.stringify(user));
  }

  getPrincipals(): any {
    return JSON.parse(sessionStorage.getItem('principal'));
  }

  removePrincipals(): void {
    sessionStorage.removeItem('principal');
  }

  getToken(): string {
    return this.getPrincipals().token;
  }

}
