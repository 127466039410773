// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  fcmSendUrl: 'https://fcm.googleapis.com/fcm/send',
  fcmServerKey: 'AIzaSyArFqSdaCa9GLC7aa_EDmeQOaQR7HVQIJc',
  restUrl: 'https://rest.citymarket.co.kr/rest',
  authUrl: 'https://rest.citymarket.co.kr/auth/login',
  fileUrl: 'https://rest.citymarket.co.kr/file'
  // restUrl: 'http://192.168.0.12:8080/rest',
  // authUrl: 'http://192.168.0.12:8080/auth/login',
  // fileUrl: 'http://192.168.0.12:8080/file'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
