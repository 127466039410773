/* tslint:disable:no-trailing-whitespace max-line-length */
/**
 * 공용 함수 모음 클래스
 */

declare var $: any;
declare var _: any;
declare var moment: any;

export class Commons {

    /**
     * 패스워드 정규식 패턴
     * pattern: 글자수 제한, 소문자, 숫자, 특수문자
     * pattern2: 글자수 제한, 소문자, 대문자, 숫자, 특수문자
     */
    public pattern = '(?=.*[a-z])(?=.*[0-9])(?=.*[$@$!%*?&])[a-z\d$@$!%*?&].{6,}';
    public pattern2 = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,}';

    /**
     * default kendo grid dataSource.sechema.data callback function
     * fields 데이터를 그리드에 바인딩 시킴 (따로 fields를 작성하지 않아도 됨)
     * @param response ajax response
     */
    defaultGridSchemaDataHandler(response) {
        if (response != null) {
            if (response.data != null) {
                if (response.data.hasOwnProperty('rs')) {
                    if (response.data.rs.length > 0) {
                        return response.data.rs[1];
                    }
                }
            }
        }
        return '';
    }

    /**
     * 결과 리스트 데이터에 전체 수 가져오기
     * @param response
     */
    getResponseTotalCount(response) {
        if (response != null) {
            if (response.data != null) {
                if (response.data.hasOwnProperty('rs')) {
                    if (response.data.rs.length > 0) {
                        return response.data.rs[0][0].total;
                    }
                }
            }
        }
        return 0;
    }

    /**
     * 결과 데이터 가져오기
     * @param response
     */
    getResponseData(response) {
        if (response != null) {
            if (response.data != null) {
                if (response.data.hasOwnProperty('rs')) {
                    if (response.data.rs.length > 0 && response.data.rs[0] != null) {
                        return response.data.rs[0][0];
                    }
                }
            }
        }
        return 0;
    }

    /**
     * 결과 리스트 데이터 가져오기
     * @param response
     */
    getResponseListData(response) {
        if (response != null) {
            if (response.data != null) {
                if (response.data.hasOwnProperty('rs')) {
                    if (response.data.rs.length > 0) {
                        return response.data.rs;
                    }
                }
            }
        }
        return 0;
    }

    /**
     * 결과 성공 여부 가져오기
     * @param response
     */
    getResponseResult(response) {
        if (response != null) {
            if (response.data != null) {
                if (response.data.hasOwnProperty('out')) {
                    return response.data.out.result === 0;
                }
            }
        }

        return false;
    }

    /**
     * 빈값 체크
     * @param value 비교할 값
     */
    isEmpty(value) {
        return _.isNaN(value) || _.isEmpty(value) || _.isNull(value) || _.isUndefined(value) || (value != null && typeof value === 'object' && !Object.keys(value).length);
    }

    /**
     * 비지 않은 값 체크
     * @param value 체크할 값
     */
    isNotEmpty(value) {
        return !this.isEmpty(value);
    }

    /**
     * 이달의 시작 일자
     */
    firstDayOfMonth() {
        return moment().startOf('month').toDate();
    }

    /**
     * 이달의 종료 일자
     */
    lastDayOfMonth() {
        return moment().endOf('month').toDate();
    }

    /**
     * 현재 날짜를 특정 포맷으로 반환
     *
     * @param formatStr 데이트 포멧 스트링
     * YYYY    2014    4 or 2 digit year
     * YY    14    2 digit year
     * Y    -25    Year with any number of digits and sign
     * Q    1..4    Quarter of year. Sets month to first month in quarter.
     * M MM    1..12    Month number
     * MMM MMMM    Jan..December    Month name in locale set by moment.locale()
     * D DD    1..31    Day of month
     * Do    1st..31st    Day of month with ordinal
     * DDD DDDD    1..365    Day of year
     * X    1410715640.579    Unix timestamp
     * x    1410715640579    Unix ms timestamp
     */
    getNowDate(formatStr?: string) {
        const _f = this.isNotEmpty(formatStr) ? formatStr : 'YYYY-MM-DD';
        return moment().format(_f);
    }

    parseDate(date: string, format: string) {
        const mDate = moment(date, format);
        return mDate.toDate();
    }

    /**
     * 끝자리 마스킹 처리
     */
    maskString(strText: string) {
        let start = 0;
        if (strText.length <= 4) {
            start = 2;
        } else {
            start = 4;
        }
        const subLength = strText.length - start;
        const prefixText = strText.substr(0, subLength);
        let suffixText = '';
        for (let i = 0; i < start; i++) {
            suffixText += '*';
        }
        return prefixText + suffixText;
    }

}

