import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {JwtModule} from '@auth0/angular-jwt';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';

// service imports
import {AuthService} from './service/auth.service';

// interceptor imports
import {ErrorInterceptor} from './interceptor/error.interceptor';
import {JwtInterceptor} from './interceptor/jwt.interceptor';

// module imports
import {AppRoutingModule} from './app-routing.module';
import {MainModule} from './views/main/main.module';
import {LayoutModule} from '@progress/kendo-angular-layout';
import {InputsModule} from '@progress/kendo-angular-inputs';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ButtonsModule} from '@progress/kendo-angular-buttons';
import {PopupModule} from '@progress/kendo-angular-popup';
import '@progress/kendo-ui';
import {EditorModule} from '@progress/kendo-angular-editor';
import {UploadModule} from '@progress/kendo-angular-upload';

// component imports
import {AppComponent} from './app.component';
import {LoginComponent} from './views/login/login.component';
import {MainComponent} from './views/main/main.component';



@NgModule({
    imports: [
        AppRoutingModule,
        MainModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        JwtModule.forRoot({config: {}}),
        LayoutModule,
        InputsModule,
        BrowserAnimationsModule,
        ButtonsModule,
        PopupModule,
        EditorModule,
        UploadModule
    ],
    providers: [
        AuthService,
        {provide: LocationStrategy, useClass: HashLocationStrategy}
    ],
    declarations: [
        AppComponent,
        LoginComponent,
        MainComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
