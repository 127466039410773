import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CommonService} from '../../../../../service/common.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ValidationFormComponent} from '../../../../common/validation-form.component';
import {RestService} from '../../../../../service/rest.service';
import {AuthService} from '../../../../../service/auth.service';
import {RestData} from '../../../../../models/rest-data';

@Component({
  selector: 'app-board-group-view',
  templateUrl: './board-group-view.component.html',
  styleUrls: ['./board-group-view.component.css']
})
export class BoardGroupViewComponent extends ValidationFormComponent implements OnInit, AfterViewInit {

  dataForm: FormGroup;

  pageData = {
    bdGrpIdx: 0,
  };

  constructor(private formBuilder: FormBuilder, private commonService: CommonService,
              private route: ActivatedRoute, private restService: RestService,
              private authService: AuthService, private router: Router) {
    super();
  }

  ngOnInit() {
    this.route.paramMap.subscribe (params => {
      this.pageData.bdGrpIdx = Number(params.get('bdGrpIdx'));
    });
    this.dataForm = this.formBuilder.group({
      P_BD_GRP_ORD: [null, Validators.required]
      , P_BD_GRP_NAME: [null, Validators.required]
      , P_STAT_CD: [null, Validators.required]
    });

    this.f.P_STAT_CD.setValue('000'); // 셀렉트박스 디폴트
  }

  ngAfterViewInit() {
    this.getData();
  }

  get f() {
    return this.dataForm.controls;
  }

  /**
   * 데이터 조회 정의
   */
  getData() {
    if (this.pageData.bdGrpIdx === 0) {
      return;
    }

    this.restService.requestAPIAuth({
      P_NAME: 'SP_ADM_BOARD_GRP_R2',
      P_ARR: JSON.stringify({
        P_BD_GRP_IDX: this.pageData.bdGrpIdx
      })
    }, 'prv', this.authService).subscribe(res => {
      this.f.P_BD_GRP_ORD.setValue(res.data.bdGrpOrd);
      this.f.P_BD_GRP_NAME.setValue(res.data.bdGrpName);
      this.f.P_STAT_CD.setValue(res.data.statCd);
    });
  }

  /**
   * 저장 및 수정
   */
  saveData() {
    if (!this.dataForm.valid) {
      this.validateAllFormFields(this.dataForm);
      return;
    }

    let msg = ((this.pageData.bdGrpIdx > 0) ? '수정 되었습니다.' : '등록 되었습니다.');
    const restData = new RestData();
    restData.P_NAME = 'SP_ADM_BOARD_GRP_CU';
    restData.P_ARR = JSON.stringify({
      P_MODE: ((this.pageData.bdGrpIdx > 0) ? 'U' : 'C'),
      P_BD_GRP_IDX: this.pageData.bdGrpIdx,
      P_BD_GRP_ORD: this.f.P_BD_GRP_ORD.value,
      P_BD_GRP_NAME: this.f.P_BD_GRP_NAME.value,
      P_STAT_CD: this.f.P_STAT_CD.value
    });
    this.restService.requestAPIAuth(restData,'prv', this.authService).subscribe(res => {
      if (!res.result) {
        alert('데이터 처리에 실패하였습니다. ');
        return;
      }
      alert(msg);
      this.commonService.movePage(['/auth/board/boardGroupList'], true);
    });
  }

}
