import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CommonService} from '../../../../../service/common.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RestService} from '../../../../../service/rest.service';
import {AuthService} from '../../../../../service/auth.service';
import {RestData} from '../../../../../models/rest-data';
import {ValidationFormComponent} from '../../../../common/validation-form.component';

@Component({
  selector: 'app-board-view',
  templateUrl: './board-view.component.html',
  styleUrls: ['./board-view.component.css']
})
export class BoardViewComponent extends ValidationFormComponent implements OnInit, AfterViewInit {

  dataForm: FormGroup;

  pageData = {
    bdIdx: 0,
    grpList: [],
    levelList: []
  };

  constructor(private formBuilder: FormBuilder, private commonService: CommonService,
              private route: ActivatedRoute, private restService: RestService,
              private authService: AuthService, private router: Router) {
    super();
  }

  ngOnInit() {
    this.route.paramMap.subscribe (params => {
      this.pageData.bdIdx = Number(params.get('bdIdx'));
    });

    this.dataForm = this.formBuilder.group({
      P_BD_GRP_IDX: [null, Validators.required]
      , P_BD_ORD: [null, Validators.required]
      , P_BD_NAME: [null, Validators.required]
      , P_PO_WRITE: [null, Validators.required]
      , P_PO_CMT: [null, Validators.required]
      , P_PO_LIKE: [null, Validators.required]
      , P_R_LEVEL_IDX: [null, Validators.required]
      , P_C_LEVEL_IDX: [null, Validators.required]
      , P_STAT_CD: [null, Validators.required]
    });

    this.f.P_STAT_CD.setValue('000'); // 셀렉트박스 디폴트
  }

  async ngAfterViewInit() {
    await this.getGroup(); // 그룹 리스트
    await this.getLevel(); // 레벨 리스트
    await this.getData(); // 상세정보
  }

  get f() {
    return this.dataForm.controls;
  }

  getGroup() {
    return this.restService.requestAPIAuth({
      P_NAME: 'SP_GET_BOARD_GRP_R',
      P_ARR: JSON.stringify({})
    }, 'prv', this.authService, true).toPromise().then((res) => {
      if (res.data.length > 0) {
        this.pageData.grpList = res.data[0];
        this.f.P_BD_GRP_IDX.setValue('');
      }
    });
  }

  getLevel() {
    return this.restService.requestAPIAuth({
      P_NAME: 'SP_GET_LEVEL_R',
      P_ARR: JSON.stringify({})
    }, 'prv', this.authService, true).toPromise().then((res) => {
      if (res.data.length > 0) {
        this.pageData.levelList = res.data[0];
        this.f.P_R_LEVEL_IDX.setValue('');
        this.f.P_C_LEVEL_IDX.setValue('');
      }
    });
  }

  /**
   * 데이터 조회 정의
   */
  getData() {
    if (this.pageData.bdIdx === 0) {
      return;
    }

    this.restService.requestAPIAuth({
      P_NAME: 'SP_ADM_BOARD_R2',
      P_ARR: JSON.stringify({
        P_BD_IDX: this.pageData.bdIdx
      })
    }, 'prv', this.authService).subscribe(res => {
      this.f.P_BD_GRP_IDX.setValue(res.data.bdGrpIdx);
      this.f.P_BD_ORD.setValue(res.data.bdOrd);
      this.f.P_BD_NAME.setValue(res.data.bdName);
      this.f.P_PO_WRITE.setValue(res.data.poWrite);
      this.f.P_PO_CMT.setValue(res.data.poCmt);
      this.f.P_PO_LIKE.setValue(res.data.poLike);
      this.f.P_R_LEVEL_IDX.setValue(res.data.rLevelIdx);
      this.f.P_C_LEVEL_IDX.setValue(res.data.cLevelIdx);
      this.f.P_STAT_CD.setValue(res.data.statCd);
    });
  }

  /**
   * 저장 및 수정
   */
  saveData() {
    if (!this.dataForm.valid) {
      this.validateAllFormFields(this.dataForm);
      return;
    }

    let msg = ((this.pageData.bdIdx > 0) ? '수정 되었습니다.' : '등록 되었습니다.');
    const restData = new RestData();
    restData.P_NAME = 'SP_ADM_BOARD_CU';
    restData.P_ARR = JSON.stringify({
      P_MODE: ((this.pageData.bdIdx > 0) ? 'U' : 'C'),
      P_BD_IDX: this.pageData.bdIdx,
      P_BD_GRP_IDX: this.f.P_BD_GRP_IDX.value,
      P_BD_ORD: this.f.P_BD_ORD.value,
      P_BD_NAME: this.f.P_BD_NAME.value,
      P_PO_WRITE: this.f.P_PO_WRITE.value,
      P_PO_CMT: this.f.P_PO_CMT.value,
      P_PO_LIKE: this.f.P_PO_LIKE.value,
      P_R_LEVEL_IDX: this.f.P_R_LEVEL_IDX.value,
      P_C_LEVEL_IDX: this.f.P_C_LEVEL_IDX.value,
      P_STAT_CD: this.f.P_STAT_CD.value
    });
    this.restService.requestAPIAuth(restData,'prv', this.authService).subscribe(res => {
      if (!res.result) {
        alert('데이터 처리에 실패하였습니다. ');
        return;
      }
      alert(msg);
      this.commonService.movePage(['/auth/board/boardList'], true);
    });
  }

}
