import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BaseListComponent} from '../../../../common/base-list.component';
import {FormBuilder, FormGroup} from '@angular/forms';
import {RestService} from '../../../../../service/rest.service';
import {AuthService} from '../../../../../service/auth.service';
import {CommonService} from '../../../../../service/common.service';

@Component({
  selector: 'app-board-post-list',
  templateUrl: './board-post-list.component.html',
  styleUrls: ['./board-post-list.component.css']
})
export class BoardPostListComponent extends BaseListComponent implements OnInit, AfterViewInit {

  searchForm: FormGroup;
  pageData = {
    grpList: [],
    bdList: []
  };

  constructor(private restService: RestService, private authService: AuthService, private commonService: CommonService,
              private formBuilder: FormBuilder) {
    super();
  }

  ngOnInit() {
    this.searchForm = this.formBuilder.group({
      P_SEARCH_KEY: ['']    // 검색인자
      , P_SEARCH_VALUE: ['']  // 검색어
      , P_BD_GRP_IDX: ['']  // 게시판 그룹별
      , P_BD_IDX: ['']  // 게시판별
    });

    // 검색인자 디폴트
    this.f.P_SEARCH_KEY.setValue((this.listItems.length > 0)? this.listItems[0].value : '');

    this.getData();
  }

  async ngAfterViewInit() {
    await this.getGroup(); // 그룹 리스트
    await this.getBoard(); // 게시판 리스트
    await this.getData(); // 상세정보
  }

  get f() {
    return this.searchForm.controls;
  }

  getGroup() {
    return this.restService.requestAPIAuth({
      P_NAME: 'SP_GET_BOARD_GRP_R',
      P_ARR: JSON.stringify({})
    }, 'prv', this.authService, true).toPromise().then((res) => {
      if (res.data.length > 0) {
        this.pageData.grpList = res.data[0];
        this.f.P_BD_GRP_IDX.setValue('');
      }
    });
  }

  getBoard() {
    return this.restService.requestAPIAuth({
      P_NAME: 'SP_GET_BOARD_R',
      P_ARR: JSON.stringify({})
    }, 'prv', this.authService, true).toPromise().then((res) => {
      if (res.data.length > 0) {
        this.pageData.bdList = res.data[0];
        this.f.P_BD_IDX.setValue('');
      }
    });
  }

  /**
   * 검색 구분자 정의
   */
  initSearchListItems() {
    this.listItems = [
      { text: '검색인자 선택.', value: '' }
      , { text: '제목', value: '1' }
      , { text: '내용', value: '2' }
      , { text: '작성자', value: '3' }
    ];
  }

  /**
   * 검색
   */
  listSearch() {
    if(this.f.P_SEARCH_KEY.value != '' || this.f.P_SEARCH_VALUE.value != '') {
      if (this.f.P_SEARCH_KEY.value == '') {
        alert('검색인자를 선택해주세요.');
        return false;
      }
      if (this.f.P_SEARCH_VALUE.value == '') {
        alert('검색어를 입력해주세요.');
        return false;
      }
    }
    this.getData();
  }

  /**
   * 데이터 조회 정의
   */
  getData() {
    this.restService.requestAPIGrid({
      P_NAME: 'SP_ADM_POST_R',
      P_ARR: JSON.stringify({
        P_SEARCH_KEY: this.f.P_SEARCH_KEY.value,
        P_SEARCH_VALUE: this.f.P_SEARCH_VALUE.value,
        P_BD_GRP_IDX: ((this.f.P_BD_GRP_IDX.value)? this.f.P_BD_GRP_IDX.value : null),
        P_BD_IDX: ((this.f.P_BD_IDX.value)? this.f.P_BD_IDX.value : null),
        P_PAGE: this.page,
        P_PAGESIZE: this.pageSize
      })
    }, 'prv', this.authService).subscribe(res => {
      this.gridData = res;
    });
  }

  /**
   * 항목 추가 페이지 이동
   */
  addItem() {
    this.commonService.movePage(['/auth/board/boardPostView', { pstIdx: 0 }], false);
  }

  /**
   * 항목 삭제 처리
   */
  deleteItem() {
    if(this.rowsSelected.length === 0) {
      alert('삭제할 항목을 선택해주세요.');
      return;
    }

    const item = this.rowsSelected[0];
    this.restService.requestAPIAuth({
      P_NAME: 'SP_ADM_POST_D',
      P_ARR: JSON.stringify({
        P_PST_IDX: item['pstIdx']
      })
    }, 'prv', this.authService).subscribe(res => {
      if(!res.result) {
        alert('데이터 삭제에 실패하였습니다.');
        return;
      }

      alert('데이터 삭제하였습니다.');
      this.getData();
    });
  }

}
