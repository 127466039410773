/* tslint:disable:no-trailing-whitespace max-line-length */
import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {first} from 'rxjs/operators';
import {AuthService} from '../../service/auth.service';
import {CommonService} from '../../service/common.service';
import {FormBuilder} from '@angular/forms';

// jquery 를 사용하고자 하는 컴포넌트 클래스에 선언해주면 주입 됨
declare var $: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

    returnUrl = '/auth/board/boardGroupList';
    loginData = {
        P_ID: ''
        , P_PWD: ''
    };
    toggleButton: any;
    sidebarVisible: boolean;
    nativeElement: Node;

    constructor(private route: ActivatedRoute, private router: Router, private commonService: CommonService,
                private formBuilder: FormBuilder, private authService: AuthService,
                private element: ElementRef) {
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
    }

    ngOnInit() {
        var navbar : HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('login-page');
        body.classList.add('off-canvas-sidebar');
        const card = document.getElementsByClassName('card')[0];
        setTimeout(function() {
            // after 1000 ms we add the class animated to the login/register card
            card.classList.remove('card-hidden');
        }, 700);
    }

    ngOnDestroy(){
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('login-page');
        body.classList.remove('off-canvas-sidebar');
    }

    sidebarToggle() {
        var toggleButton = this.toggleButton;
        var body = document.getElementsByTagName('body')[0];
        var sidebar = document.getElementsByClassName('navbar-collapse')[0];
        if (this.sidebarVisible == false) {
            setTimeout(function() {
                toggleButton.classList.add('toggled');
            }, 500);
            body.classList.add('nav-open');
            this.sidebarVisible = true;
        } else {
            this.toggleButton.classList.remove('toggled');
            this.sidebarVisible = false;
            body.classList.remove('nav-open');
        }
    }

    // 로그인
    onSubmit() {

        if (this.loginData.P_ID.trim() === '') {
            alert('아이디를 입력해 주세요.');
            return false;
        }

        if (this.loginData.P_PWD.trim() === '') {
            alert('비밀번호를 입력해 주세요.');
            return false;
        }

        this.authService.login(this.loginData.P_ID, this.loginData.P_PWD)
            .pipe(first())
            .subscribe(
                data => {
                    // 페이지 이동
                    this.commonService.movePage([this.returnUrl], true);
                },
                err => {
                    console.log('login error::', err.error.message);
                    alert(err.error.message);
                    return false;
                }
            );
    }

}
