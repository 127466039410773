import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {MainRoutingModule} from './main-routing.module';
import {BodyModule, GridModule} from '@progress/kendo-angular-grid';
import {DropDownListModule} from '@progress/kendo-angular-dropdowns';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {EditorModule} from "@progress/kendo-angular-editor";
import {UploadModule} from "@progress/kendo-angular-upload";

import {SideComponent} from './shared/side/side.component';
import {FooterComponent} from './shared/footer/footer.component';
import {NavbarComponent} from './shared/navbar/navbar.component';
import {FixedpluginComponent} from './shared/fixedplugin/fixedplugin.component';

import {BoardGroupListComponent} from './content/boardGroup/board-group-list/board-group-list.component';
import {BoardGroupViewComponent} from './content/boardGroup/board-group-view/board-group-view.component';
import {BoardListComponent} from './content/board/board-list/board-list.component';
import {BoardViewComponent} from './content/board/board-view/board-view.component';
import {BoardPostListComponent} from './content/boardPost/board-post-list/board-post-list.component';
import {BoardPostViewComponent} from './content/boardPost/board-post-view/board-post-view.component';
import {MessageListComponent} from './content/message/message-list/message-list.component';
import {LevelListComponent} from './content/level/level-list/level-list.component';
import {LevelViewComponent} from './content/level/level-view/level-view.component';
import {AdminListComponent} from './content/user/admin-list/admin-list.component';
import {AdminViewComponent} from './content/user/admin-view/admin-view.component';
import {UserListComponent} from './content/user/user-list/user-list.component';
import {UserViewComponent} from './content/user/user-view/user-view.component';
import {ReportListComponent} from './content/report/report-list/report-list.component';
import {ReportViewComponent} from './content/report/report-view/report-view.component';
import {ReportReasonListComponent} from './content/reportReason/report-reason-list/report-reason-list.component';
import {ReportReasonViewComponent} from './content/reportReason/report-reason-view/report-reason-view.component';
import {VisualListComponent} from './content/setting/visual-list/visual-list.component';
import {VisualViewComponent} from './content/setting/visual-view/visual-view.component';
import {TermsComponent} from './content/setting/terms/terms.component';
import {BaseListComponent} from '../common/base-list.component';
import {ValidationFormComponent} from '../common/validation-form.component';
import {FieldErrorDisplayComponent} from '../common/field-error-display/field-error-display.component';
import {SendPushComponent} from './content/push/send-push/send-push.component';
import {PointListComponent} from './content/point/point-list/point-list.component';
import {PointAddComponent} from './content/point/point-add/point-add.component';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MainRoutingModule,
    FormsModule,
    GridModule,
    // SharedModule,
    DropDownListModule,
    BodyModule,
    MatInputModule,
    MatSelectModule,
    EditorModule,
    UploadModule
  ],
  exports: [
    SideComponent,
    FooterComponent,
    NavbarComponent,
    FixedpluginComponent,
    MatInputModule,
    MatSelectModule
  ],
  declarations: [
    SideComponent,
    FooterComponent,
    NavbarComponent,
    FixedpluginComponent,
    BoardGroupListComponent,
    BoardGroupViewComponent,
    BoardListComponent,
    BoardViewComponent,
    BoardPostListComponent,
    BoardPostViewComponent,
    MessageListComponent,
    LevelListComponent,
    LevelViewComponent,
    AdminListComponent,
    AdminViewComponent,
    UserListComponent,
    UserViewComponent,
    ReportListComponent,
    ReportViewComponent,
    ReportReasonListComponent,
    ReportReasonViewComponent,
    VisualListComponent,
    VisualViewComponent,
    TermsComponent,
    BaseListComponent,
    ValidationFormComponent,
    FieldErrorDisplayComponent,
    SendPushComponent,
    PointListComponent,
    PointAddComponent
  ]
})
export class MainModule { }
