import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ValidationFormComponent} from '../../../../common/validation-form.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CommonService} from '../../../../../service/common.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RestService} from '../../../../../service/rest.service';
import {AuthService} from '../../../../../service/auth.service';
import {RestData} from '../../../../../models/rest-data';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.css']
})
export class UserViewComponent extends ValidationFormComponent implements OnInit, AfterViewInit {

  dataForm: FormGroup;
  pageData = {
    usrIdx: 0
    , emailChk: false
    , oldEmail: ''
  };

  constructor(private formBuilder: FormBuilder, private commonService: CommonService,
              private route: ActivatedRoute, private restService: RestService,
              private authService: AuthService, private router: Router) {
    super();
  }

  ngOnInit() {
    this.route.paramMap.subscribe (params => {
      this.pageData.usrIdx = Number(params.get('usrIdx'));
    });

    this.dataForm = this.formBuilder.group({
      P_EMAIL: [null, Validators.required]
      , P_PWD: [null]
      , P_NAME: [null, Validators.required]
      , P_PHONE: ['', [Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]]
      , P_STAT_CD: [null, Validators.required]
    });

    if(this.pageData.usrIdx == 0) {
      this.f.P_PWD.setValidators([Validators.required]);
    }

    this.f.P_STAT_CD.setValue('000'); // 셀렉트박스 디폴트
  }

  async ngAfterViewInit() {
    await this.getData(); // 상세정보
  }

  get f() {
    return this.dataForm.controls;
  }

  emailValidation() {
    return this.restService.requestAPIAuth({
      P_NAME: 'SP_ADM_USER_R3',
      P_ARR: JSON.stringify({
        P_EMAIL: this.f.P_EMAIL.value
      })
    }, 'prv', this.authService).toPromise().then((res) => {
      if (res.data) {
        this.pageData.emailChk = true;
        return true;
      } else {
        this.pageData.emailChk = false;
        return false;
      }
    });
  }

  /**
   * 데이터 조회 정의
   */
  getData() {
    if (this.pageData.usrIdx === 0) {
      return;
    }

    this.restService.requestAPIAuth({
      P_NAME: 'SP_ADM_USER_R2',
      P_ARR: JSON.stringify({
        P_USR_IDX: this.pageData.usrIdx
      })
    }, 'prv', this.authService).subscribe(res => {
      this.pageData.oldEmail = res.data.email;
      this.f.P_EMAIL.setValue(res.data.email);
      this.f.P_NAME.setValue(res.data.name);
      this.f.P_PHONE.setValue(res.data.phone);
      this.f.P_STAT_CD.setValue(res.data.statCd);
    });
  }

  /**
   * 저장 및 수정
   */
  async saveData() {
    if (!this.dataForm.valid) {
      this.validateAllFormFields(this.dataForm);
      return;
    }

    // 아이디 중복 체크
    if(this.pageData.oldEmail != this.f.P_EMAIL.value) {
      if(await this.emailValidation()) {
        return;
      }
    }

    let msg = ((this.pageData.usrIdx > 0) ? '수정 되었습니다.' : '등록 되었습니다.');
    const restData = new RestData();
    restData.P_NAME = 'SP_ADM_USER_CU';
    restData.P_ARR = JSON.stringify({
      P_MODE: ((this.pageData.usrIdx > 0) ? 'U' : 'C')
      , P_USR_IDX: this.pageData.usrIdx
      , P_EMAIL: this.f.P_EMAIL.value
      , P_PWD: ((this.f.P_PWD.value) ? this.f.P_PWD.value : '')
      , P_NAME: this.f.P_NAME.value
      , P_PHONE: String(this.f.P_PHONE.value)
      , P_STAT_CD: this.f.P_STAT_CD.value
    });
    this.restService.requestAPIAuth(restData,'prv', this.authService).subscribe(res => {
      if (!res.result) {
        alert('데이터 처리에 실패하였습니다. ');
        return;
      }
      alert(msg);
      this.commonService.movePage(['/auth/user/userList'], true);
    });
  }

}
