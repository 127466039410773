/* tslint:disable:no-trailing-whitespace max-line-length no-redundant-jsdoc */
import {Component} from '@angular/core';
import {GridDataResult, PageChangeEvent, SelectableSettings} from '@progress/kendo-angular-grid';

export class SelecteItem {
    text: string;
    value: string;
}

@Component({
    template: ''
})
export class BaseListComponent {

    /**
     * 리스트에서 첫번째 헤더 체크박스 공통 세팅
     */
    selectableSettings: SelectableSettings = {
        checkboxOnly: true,
        mode: 'single'
    };
    rowsSelected: number[] = [];

    // 페이징 관련
    skip = 0;
    pageSize = 25;
    page = 1;

    // 검색 관련 파라미터
    searchKey = '';
    searchValue = '';

    // 리스트 표시 데이터
    gridData: GridDataResult;

    // 검색 폼 연동 데이터
    listItems: Array<SelecteItem>;
    ngSearchKey: SelecteItem;
    ngSearchValue = '';

    selectedCallback = (args) => args.dataItem;

    constructor() {
        // 검색 구분자 정의 실행
        this.initSearchListItems();
        if (this.listItems.length > 0) {
            this.ngSearchKey = this.listItems[0];
        }
    }

    /**
     * 검색 구분자 정의
     */
    initSearchListItems() { }

    /**
     * 검색 구분자 변경 이벤트
     * @param value
     */
    searchSelectChange(value: any) {
        this.ngSearchKey = value;
    }

    /**
     * 페이징 처리 이벤트
     * @param event
     */
    pageChange(event: PageChangeEvent): void {
        this.skip = event.skip;
        this.page = (event.skip / this.pageSize) + 1;

        // 데이터 조회
        this.getData();
    }

    /**
     * 검색 실행
     */
    search() {
        // 페이징 초기화
        this.skip = 0;
        this.page = 1;

        // 검색 값 정의
        this.searchKey = this.ngSearchKey.value;
        this.searchValue = this.ngSearchValue;

        // 데이터 조회
        this.getData();
    }

    /**
     * 데이터 조회하여 리스트 내용 표시
     */
    getData() { }

}
