import {AfterViewInit, Component, OnInit} from '@angular/core';
import {CommonService} from "../../../../../service/common.service";
import {ActivatedRoute} from "@angular/router";
import {RestService} from "../../../../../service/rest.service";
import {AuthService} from "../../../../../service/auth.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BaseListComponent} from "../../../../common/base-list.component";
import {FcmService} from "../../../../../service/fcm.service";
import {FcmBody} from "../../../../../models/fcm-body";

declare var $: any;

@Component({
  selector: 'app-send-push',
  templateUrl: './send-push.component.html',
  styleUrls: ['./send-push.component.css']
})
export class SendPushComponent extends BaseListComponent implements OnInit, AfterViewInit {

  searchForm: FormGroup;
  dataForm: FormGroup;
  pageData = {
    termsList: []
  };

  constructor(private commonService: CommonService, private route: ActivatedRoute, private restService: RestService,
              private authService: AuthService, private formBuilder: FormBuilder
      , private fcm: FcmService) {
    super();
  }

  ngOnInit() {
    this.selectableSettings.mode = 'multiple';

    this.searchForm = this.formBuilder.group({
      P_SEARCH_KEY: ['']    // 검색인자
      , P_SEARCH_VALUE: ['']  // 검색어
    });

    // 검색인자 디폴트
    this.sch_f.P_SEARCH_KEY.setValue((this.listItems.length > 0)? this.listItems[0].value : '');

    this.dataForm = this.formBuilder.group({
      P_SUBJECT: [null, Validators.required]
      , P_CONTENT: [null, Validators.required]
    });
  }

  ngAfterViewInit() {
    this.getData();
  }

  get sch_f() {
    return this.searchForm.controls;
  }

  get data_f() {
    return this.dataForm.controls;
  }

  /**
   * 검색 구분자 정의
   */
  initSearchListItems() {
    this.listItems = [
      { text: '검색인자 선택.', value: '' }
      , { text: '이메일', value: '1' }
      , { text: '이름', value: '2' }
      , { text: '레벨', value: '3' }
    ];
  }

  /**
   * 검색
   */
  listSearch() {
    if(this.sch_f.P_SEARCH_KEY.value != '' || this.sch_f.P_SEARCH_VALUE.value != '') {
      if (this.sch_f.P_SEARCH_KEY.value == '') {
        alert('검색인자를 선택해주세요.');
        return false;
      }
      if (this.sch_f.P_SEARCH_VALUE.value == '') {
        alert('검색어를 입력해주세요.');
        return false;
      }
    }
    this.getData();
  }

  /**
   * 데이터 조회 정의
   */
  getData() {
    this.restService.requestAPIGrid({
      P_NAME: 'SP_ADM_USER_PUSH_R',
      P_ARR: JSON.stringify({
        P_SEARCH_KEY: this.sch_f.P_SEARCH_KEY.value,
        P_SEARCH_VALUE: this.sch_f.P_SEARCH_VALUE.value,
        P_PAGE: this.page,
        P_PAGESIZE: this.pageSize
      })
    }, 'prv', this.authService).subscribe(res => {
      this.gridData = res;
    });
  }

  /**
   * 저장 및 수정
   */
  async saveData() {
    const item = this.rowsSelected;
    if (item.length === 0) {
      alert('회원을 선택해주세요.');
      return;
    }
    if (!this.data_f.P_SUBJECT.value) {
      alert('제목을 입력해주세요.');
      return;
    }
    if (!this.data_f.P_CONTENT.value) {
      alert('내용을 입력해주세요.');
      return;
    }

    let arrError = [];
    for (let i = 0; i < item.length; i++) {
      const fcmBody: FcmBody = new FcmBody();
      fcmBody.os = item[i]['deviceOs'];
      fcmBody.to = item[i]['pushToken'];
      fcmBody.title = this.data_f.P_SUBJECT.value;
      fcmBody.body = this.data_f.P_CONTENT.value;
      // 푸시 전송
      await this.fcm.sendPush(fcmBody).toPromise().then(
          res => {
            // @ts-ignore
            if (res.failure > 0) {
              // @ts-ignore
              arrError.push({name: item[i]['name'], error: res.results[0].error});
            }
          },
          error => {
            arrError.push({name: item[i]['name'], error: error.message});
          }
      );
    }

    if (arrError.length > 0) {
      let msg = '푸시 전송 중 에러가 발생하였습니다.\n';
      for (let i = 0; i < arrError.length; i++) {
        if (i != 0) {
          msg += '\n';
        }
        msg += arrError[i]['name'] + ' : ' + arrError[i]['error'];
      }
      alert(msg);
      return;
    } else {
      alert('전송 완료하였습니다.');
      this.dataForm.reset();
      this.getData();
    }

  }



}
