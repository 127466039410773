import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BaseListComponent} from "../../../../common/base-list.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CommonService} from "../../../../../service/common.service";
import {ActivatedRoute} from "@angular/router";
import {RestService} from "../../../../../service/rest.service";
import {AuthService} from "../../../../../service/auth.service";
import {FcmService} from "../../../../../service/fcm.service";
import {FcmBody} from "../../../../../models/fcm-body";
import {RestData} from "../../../../../models/rest-data";

declare var $: any;

@Component({
  selector: 'app-point-add',
  templateUrl: './point-add.component.html',
  styleUrls: ['./point-add.component.css']
})
export class PointAddComponent extends BaseListComponent implements OnInit, AfterViewInit {

  searchForm: FormGroup;
  dataForm: FormGroup;
  pageData = {
    termsList: []
  };

  constructor(private commonService: CommonService, private route: ActivatedRoute, private restService: RestService,
              private authService: AuthService, private formBuilder: FormBuilder
      , private fcm: FcmService) {
    super();
  }

  ngOnInit() {
    this.selectableSettings.mode = 'multiple';

    this.searchForm = this.formBuilder.group({
      P_SEARCH_KEY: ['']    // 검색인자
      , P_SEARCH_VALUE: ['']  // 검색어
    });

    // 검색인자 디폴트
    this.sch_f.P_SEARCH_KEY.setValue((this.listItems.length > 0)? this.listItems[0].value : '');

    this.dataForm = this.formBuilder.group({
      P_DTL_CD: [null, Validators.required]
      , P_POINT: [null, Validators.required]
      , P_MEMO: [null]
    });

    this.data_f.P_DTL_CD.setValue('004'); // 셀렉트박스 디폴트
  }

  ngAfterViewInit() {
    this.getData();
  }

  get sch_f() {
    return this.searchForm.controls;
  }

  get data_f() {
    return this.dataForm.controls;
  }

  /**
   * 검색 구분자 정의
   */
  initSearchListItems() {
    this.listItems = [
      { text: '검색인자 선택.', value: '' }
      , { text: '이메일', value: '1' }
      , { text: '이름', value: '2' }
      , { text: '레벨', value: '3' }
    ];
  }

  /**
   * 검색
   */
  listSearch() {
    if(this.sch_f.P_SEARCH_KEY.value != '' || this.sch_f.P_SEARCH_VALUE.value != '') {
      if (this.sch_f.P_SEARCH_KEY.value == '') {
        alert('검색인자를 선택해주세요.');
        return false;
      }
      if (this.sch_f.P_SEARCH_VALUE.value == '') {
        alert('검색어를 입력해주세요.');
        return false;
      }
    }
    this.getData();
  }

  /**
   * 데이터 조회 정의
   */
  getData() {
    this.restService.requestAPIGrid({
      P_NAME: 'SP_ADM_USER_PUSH_R',
      P_ARR: JSON.stringify({
        P_SEARCH_KEY: this.sch_f.P_SEARCH_KEY.value,
        P_SEARCH_VALUE: this.sch_f.P_SEARCH_VALUE.value,
        P_PAGE: this.page,
        P_PAGESIZE: this.pageSize
      })
    }, 'prv', this.authService).subscribe(res => {
      this.gridData = res;
    });
  }

  /**
   * 저장 및 수정
   */
  async saveData() {
    const item = this.rowsSelected;
    if (item.length === 0) {
      alert('회원을 선택해주세요.');
      return;
    }
    if (!this.data_f.P_POINT.value) {
      alert('포인트를 입력해주세요.');
      return;
    }

    let pointData = "";
    for (let i = 0; i < item.length; i++) {
      if (i != 0) { pointData += "|"; }
      pointData += item[i]['usrIdx'];
      pointData += ":"+this.data_f.P_DTL_CD.value;
      pointData += ":"+this.data_f.P_POINT.value;
      pointData += ":"+this.data_f.P_MEMO.value;
    }

    const restData = new RestData();
    restData.P_NAME = 'SP_ADM_POINT_C';
    restData.P_ARR = JSON.stringify({
      P_DATA: pointData,
    });
    this.restService.requestAPIAuth(restData,'prv', this.authService).subscribe(res => {
      if (!res.result) {
        alert('데이터 처리에 실패하였습니다. ');
        return;
      }
      alert('처리 되었습니다.');
      this.commonService.movePage(['/auth/point/pointList'], true);
    });

  }

}
