import { Component, OnInit } from '@angular/core';
import {BaseListComponent} from "../../../../common/base-list.component";
import {FormBuilder, FormGroup} from "@angular/forms";
import {RestService} from "../../../../../service/rest.service";
import {AuthService} from "../../../../../service/auth.service";
import {CommonService} from "../../../../../service/common.service";

@Component({
    selector: 'app-visual-list',
    templateUrl: './visual-list.component.html',
    styleUrls: ['./visual-list.component.css']
})
export class VisualListComponent extends BaseListComponent implements OnInit {

    searchForm: FormGroup;

    constructor(private restService: RestService, private authService: AuthService,
                private commonService: CommonService, private formBuilder: FormBuilder) {
        super();
    }

    ngOnInit() {
        this.searchForm = this.formBuilder.group({
        });
        // 검색인자 디폴트
        this.searchForm.patchValue({
        });

        this.getData();
    }

    get f() {
        return this.searchForm.controls;
    }

    /**
     * 검색 구분자 정의
     */
    initSearchListItems() {
        this.listItems = [
        ];
    }

    /**
     * 검색
     */
    listSearch() {
        // if(this.f.P_SEARCH_KEY.value != '' || this.f.P_SEARCH_VALUE.value != '') {
        //     if (this.f.P_SEARCH_KEY.value == '') {
        //         alert('검색인자를 선택해주세요.');
        //         return false;
        //     }
        //     if (this.f.P_SEARCH_VALUE.value == '') {
        //         alert('검색어를 입력해주세요.');
        //         return false;
        //     }
        // }
        this.getData();
    }

    /**
     * 데이터 조회 정의
     */
    getData() {
        this.restService.requestAPIGrid({
            P_NAME: 'SP_ADM_VISUAL_R',
            P_ARR: JSON.stringify({
                P_PAGE: this.page,
                P_PAGESIZE: this.pageSize
            })
        }, 'prv', this.authService).subscribe(res => {
            this.gridData = res;
        });
    }

    /**
     * 항목 추가 페이지 이동
     */
    addItem() {
        this.commonService.movePage(['/auth/setting/visualView', {vsIdx: 0 }], false);
    }

    /**
     * 항목 삭제 처리
     */
    deleteItem() {
        if(this.rowsSelected.length === 0) {
            alert('삭제할 항목을 선택해주세요.');
            return;
        }
        if(!confirm('정말로 삭제하시겠습니까?')) {
            return;
        }

        const item = this.rowsSelected[0];
        this.restService.requestAPIAuth({
            P_NAME: 'SP_ADM_VISUAL_D',
            P_ARR: JSON.stringify({
                P_VS_IDX: item['vsIdx']
            })
        }, 'prv', this.authService).subscribe(res => {
            if(!res.result) {
                alert('데이터 삭제에 실패하였습니다.');
                return;
            }

            if (item['fIdx'] > 0) {
                this.commonService.fileDelete(item['fIdx']);
            }
            alert('삭제하였습니다.');
            this.getData();
        });
    }

}
