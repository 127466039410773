import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ValidationFormComponent} from "../../../../common/validation-form.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CommonService} from "../../../../../service/common.service";
import {ActivatedRoute, Router} from "@angular/router";
import {RestService} from "../../../../../service/rest.service";
import {AuthService} from "../../../../../service/auth.service";
import {RestData} from "../../../../../models/rest-data";
import {FileRestrictions} from "@progress/kendo-angular-upload";

@Component({
  selector: 'app-visual-view',
  templateUrl: './visual-view.component.html',
  styleUrls: ['./visual-view.component.css']
})
export class VisualViewComponent extends ValidationFormComponent implements OnInit, AfterViewInit {

  // @ts-ignore
  @ViewChild('fileInput') fileInput: any;
  dataForm: FormGroup;
  pageData = {
    vsIdx: 0,
    ord: 0,
    fIdx: 0,
    fSeq: 0,
    fSvrName: '',
    fOrgName: '',
    newFIdx: 0
  };
  visualFile: FileList; // 이미지 첨부

  constructor(private formBuilder: FormBuilder, private commonService: CommonService,
              private route: ActivatedRoute, private restService: RestService,
              private authService: AuthService, private router: Router) {
    super();
  }

  ngOnInit() {
    this.route.paramMap.subscribe (params => {
      this.pageData.vsIdx = Number(params.get('vsIdx'));
    });
    this.dataForm = this.formBuilder.group({
      P_ORD: [null, Validators.required]
      , P_LINK: ['']
      , P_LINK_TYPE: [null, Validators.required]
    });

    this.f.P_LINK_TYPE.setValue('0'); // 셀렉트박스 디폴트
  }

  ngAfterViewInit() {
    this.getData();
  }

  get f() {
    return this.dataForm.controls;
  }

  /**
   * 데이터 조회 정의
   */
  getData() {
    if (this.pageData.vsIdx === 0) {
      return;
    }

    this.restService.requestAPIAuth({
      P_NAME: 'SP_ADM_VISUAL_R2',
      P_ARR: JSON.stringify({
        P_VS_IDX: this.pageData.vsIdx
      })
    }, 'prv', this.authService).subscribe(res => {
      this.f.P_ORD.setValue(res.data.ord);
      this.pageData.fIdx = res.data.fIdx;
      this.pageData.fSeq = res.data.fSeq;
      this.pageData.fSvrName = res.data.fSvrName;
      this.pageData.fOrgName = res.data.fOrgName;
      this.f.P_LINK.setValue(res.data.link);
      this.f.P_LINK_TYPE.setValue(res.data.linkType);
    });
  }

  /**
   * 파일 초기화
   */
  fileReset() {
    this.visualFile = null;
    this.fileInput.nativeElement.value = null;
  }

  /**
   * 저장 및 수정
   */
  async saveData() {
    if (!this.dataForm.valid) {
      this.validateAllFormFields(this.dataForm);
      return;
    }

    // 이미지 변경 시
    if (this.visualFile) {
      if (this.pageData.newFIdx > 0) {
        await this.commonService.fileDelete(this.pageData.newFIdx);
      }
      this.pageData.newFIdx = await this.commonService.fileUpload(this.visualFile, 'visual');
    }

    if (this.pageData.newFIdx <= 0 && this.pageData.vsIdx == 0) {
      alert('이미지를 등록해 주세요.');
      return;
    }

    let msg = ((this.pageData.vsIdx > 0) ? '수정 되었습니다.' : '등록 되었습니다.');
    const restData = new RestData();
    restData.P_NAME = 'SP_ADM_VISUAL_CU';
    restData.P_ARR = JSON.stringify({
      P_MODE: ((this.pageData.vsIdx > 0) ? 'U' : 'C'),
      P_VS_IDX: this.pageData.vsIdx,
      P_ORD: this.f.P_ORD.value,
      P_LINK: this.f.P_LINK.value,
      P_LINK_TYPE: this.f.P_LINK_TYPE.value,
      P_F_IDX: ((this.pageData.newFIdx > 0)? this.pageData.newFIdx : this.pageData.fIdx)
    });
    this.restService.requestAPIAuth(restData,'prv', this.authService).subscribe(res => {
      if (!res.result) {
        alert('데이터 처리에 실패하였습니다. ');
        return;
      }
      if (this.visualFile && this.pageData.fIdx > -1) { // 이전 파일 삭제
        this.commonService.fileDelete(this.pageData.fIdx);
      }

      alert(msg);
      this.commonService.movePage(['/auth/setting/visualList'], true);
    });
  }

}
