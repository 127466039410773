export class User {
    usrIdx: number;
    usrId: string;
    usrNm: string;
    token: string;
    constructor(usrIdx: number, usrId: string, usrNm: string, token: string) {
        this.usrIdx = usrIdx;
        this.usrId = usrId;
        this.usrNm = usrNm;
        this.token = token;
    }

    // 회원정보
    grantLevel: number;

}
