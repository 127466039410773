import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ValidationFormComponent} from '../../../../common/validation-form.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CommonService} from '../../../../../service/common.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RestService} from '../../../../../service/rest.service';
import {AuthService} from '../../../../../service/auth.service';
import {RestData} from '../../../../../models/rest-data';

@Component({
  selector: 'app-admin-view',
  templateUrl: './admin-view.component.html',
  styleUrls: ['./admin-view.component.css']
})
export class AdminViewComponent extends ValidationFormComponent implements OnInit, AfterViewInit {

  dataForm: FormGroup;
  pageData = {
    admIdx: 0
    , idChk: false
    , oldId: ''
  };

  constructor(private formBuilder: FormBuilder, private commonService: CommonService,
              private route: ActivatedRoute, private restService: RestService,
              private authService: AuthService, private router: Router) {
    super();
  }

  ngOnInit() {
    this.route.paramMap.subscribe (params => {
      this.pageData.admIdx = Number(params.get('admIdx'));
    });

    this.dataForm = this.formBuilder.group({
      P_ID: [null, Validators.required]
      , P_PWD: [null]
      , P_NAME: [null, Validators.required]
      , P_GRANT_LEVEL: [null, Validators.required]
    });

    if(this.pageData.admIdx == 0) {
      this.f.P_PWD.setValidators([Validators.required]);
    }

    this.f.P_GRANT_LEVEL.setValue('1'); // 셀렉트박스 디폴트
  }

  async ngAfterViewInit() {
    await this.getData(); // 상세정보
  }

  get f() {
    return this.dataForm.controls;
  }

  idValidation() {
    return this.restService.requestAPIAuth({
      P_NAME: 'SP_ADM_ADMIN_R3',
      P_ARR: JSON.stringify({
        P_ID: this.f.P_ID.value
      })
    }, 'prv', this.authService).toPromise().then((res) => {
      if (res.data) {
        this.pageData.idChk = true;
        return true;
      } else {
        this.pageData.idChk = false;
        return false;
      }
    });
  }

  /**
   * 데이터 조회 정의
   */
  getData() {
    if (this.pageData.admIdx === 0) {
      return;
    }

    this.restService.requestAPIAuth({
      P_NAME: 'SP_ADM_ADMIN_R2',
      P_ARR: JSON.stringify({
        P_ADM_IDX: this.pageData.admIdx
      })
    }, 'prv', this.authService).subscribe(res => {
      this.pageData.oldId = res.data.id;
      this.f.P_ID.setValue(res.data.id);
      this.f.P_NAME.setValue(res.data.name);
      this.f.P_GRANT_LEVEL.setValue(res.data.grantLevel);
    });
  }

  /**
   * 저장 및 수정
   */
  async saveData() {
    if (!this.dataForm.valid) {
      this.validateAllFormFields(this.dataForm);
      return;
    }

    // 아이디 중복 체크
    if(this.pageData.oldId != this.f.P_ID.value) {
      if(await this.idValidation()) {
        return;
      }
    }

    let msg = ((this.pageData.admIdx > 0) ? '수정 되었습니다.' : '등록 되었습니다.');
    const restData = new RestData();
    restData.P_NAME = 'SP_ADM_ADMIN_CU';
    restData.P_ARR = JSON.stringify({
      P_MODE: ((this.pageData.admIdx > 0) ? 'U' : 'C')
      ,P_ADM_IDX: this.pageData.admIdx
      ,P_ID: this.f.P_ID.value
      ,P_PWD: ((this.f.P_PWD.value) ? this.f.P_PWD.value : '')
      ,P_NAME: this.f.P_NAME.value
      ,P_GRANT_LEVEL: this.f.P_GRANT_LEVEL.value
    });
    this.restService.requestAPIAuth(restData,'prv', this.authService).subscribe(res => {
      if (!res.result) {
        alert('데이터 처리에 실패하였습니다. ');
        return;
      }
      alert(msg);
      this.commonService.movePage(['/auth/user/adminList'], true);
    });
  }

}
