import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {AuthGuard} from './guard/auth.guard';

// app component imports
import {LoginComponent} from './views/login/login.component';
import {MainComponent} from './views/main/main.component';


const routes: Routes = [
  { path: '', redirectTo: '/auth/board/boardGroupList', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'auth',
        loadChildren: './views/main/main.module#MainModule'
      },
    ],
    canActivate: [AuthGuard]
  }
  , { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true })  // real
    // RouterModule.forRoot(routes, { enableTracing: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
