import {Component, NgZone, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AuthService} from '../../../../service/auth.service';
import {RestService} from '../../../../service/rest.service';
import {CommonService} from '../../../../service/common.service';
import PerfectScrollbar from 'perfect-scrollbar';
import {Location} from '@angular/common';
import {User} from '../../../../models/user';

declare const $: any;

@Component({
  selector: 'app-side',
  templateUrl: './side.component.html',
  styleUrls: ['./side.component.css']
})
export class SideComponent implements OnInit {

  menuItems: any[];
  ps: any;

  constructor(private router: Router, private route: ActivatedRoute, private authService: AuthService, private restService: RestService,
              private commonService: CommonService, private location: Location, private ngZone: NgZone) {
  }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = document.querySelector('.sidebar .sidebar-wrapper') as HTMLElement;
      this.ps = new PerfectScrollbar(elemSidebar);
    }

    this.router.events.subscribe (event => {
      if (event instanceof NavigationEnd) {
        if (this.authService.principalValue) {
          // 관리자 정보 조회
          this.getAdminInfo();
        }
      }
    });
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  updatePS() {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      this.ps.update();
    }
  }

  isMac() {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      bool = true;
    }
    return bool;
  }

  getActive() {
    const listTitles = ROUTES.filter(listTitle => listTitle);
    let titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice( 1 );
    }
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < listTitles.length; i++) {
      if (listTitles[i].type === 'link' && listTitles[i].path === titlee) {
        return listTitles[i].title;
      } else if (listTitles[i].type === 'sub') {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < listTitles[i].children.length; j++) {
          $('.collapse').find('li').removeClass('active');
          const subtitle = listTitles[i].path + '/' + listTitles[i].children[j].path;
          const chPath = titlee.split(';');
          const id = (listTitles[i].children[j].type) ? listTitles[i].children[j].type : listTitles[i].children[j].path;
          if (subtitle === chPath[0]) {
            $('#' + id).addClass('active');
            return 'active';
          }
        }
      }
    }
  }

  // 관리자 정보 조회
  getAdminInfo() {
    this.restService.requestAPIAuth({
      P_NAME: 'SP_ADM_ADMIN_R2',
      P_ARR: JSON.stringify({
        P_ADM_IDX: this.authService.principalValue.usrIdx,
      })
    }, 'prv', this.authService).subscribe(res => {
      // @ts-ignore
      this.ngZone.run(() => {
        // 세션 사용자 정보 저장
        const usr: User = this.authService.principalValue;
        usr.grantLevel = res.data.grantLevel;
        this.authService.setPrincipals(usr);
        this.authService.currentUserSubject.next(usr);
      });
    }, error => {
      console.log('error: ', error);
    });
  }

}

// Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  children?: ChildrenItems[];
}
export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}
// Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: '/auth/board',
    title: '게시판 관리',
    type: 'sub',
    icontype: 'assignment',
    collapse: 'boards',
    children: [
      {path: 'boardGroupList', title: '그룹관리', ab: ''},
      {path: 'boardGroupView', title: '그룹관리', ab: '', type: 'boardGroupList'},
      {path: 'boardList', title: '게시판 관리', ab: ''},
      {path: 'boardView', title: '게시판 관리', ab: '', type: 'boardList'},
      {path: 'boardPostList', title: '작성글 관리', ab: ''},
      {path: 'boardPostView', title: '작성글 관리', ab: '', type: 'boardPostList'}
    ]
  }
  , {
    path: '/auth/user',
    title: '회원 관리',
    type: 'sub',
    icontype: 'people',
    collapse: 'users',
    children: [
      {path: 'adminList', title: '관리자 관리', ab: ''},
      {path: 'adminView', title: '관리자 관리', ab: '', type: 'adminList'},
      {path: 'userList', title: '회원 관리', ab: ''},
      {path: 'userView', title: '회원 관리', ab: '', type: 'userList'}
    ]
  }
  , {
    path: '/auth/level/levelList',
    title: '레벨 관리',
    type: 'link',
    icontype: 'signal_cellular_alt'
  }
  , {
    path: '/auth/report',
    title: '신고 관리',
    type: 'sub',
    icontype: 'notification_important',
    collapse: 'reports',
    children: [
      {path: 'reportReasonList', title: '신고사유 관리', ab: ''},
      {path: 'reportReasonView', title: '신고사유 관리', ab: '', type: 'reportReasonList'},
      {path: 'reportList', title: '신고 관리', ab: ''},
      {path: 'reportView', title: '신고 관리', ab: '', type: 'reportList'}
    ]
  }
  , {
    path: '/auth/message/messageList',
    title: '쪽지 관리',
    type: 'link',
    icontype: 'send'
  }
  , {
    path: '/auth/setting',
    title: '사이트 관리',
    type: 'sub',
    icontype: 'dvr',
    collapse: 'settings',
    children: [
      {path: 'visualList', title: '메인 배너 관리', ab:''},
      {path: 'visualView', title: '메인 배너 관리', ab:'', type: 'visualList'},
      {path: 'terms', title: '약관 관리', ab:''}
    ]
  }, {
    path: '/auth/push/sendPush',
    title: '푸시 전송',
    type: 'link',
    icontype: 'notifications_active'
  }
  , {
    path: '/auth/point',
    title: '포인트 관리',
    type: 'sub',
    icontype: 'monetization_on',
    collapse: 'points',
    children: [
      {path: 'pointList', title: '포인트 내역', ab:''},
      {path: 'pointAdd', title: '포인트 적립 및 차감', ab:''}
    ]
  }
];
